import {
  Box,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Feature } from './Feature';

function FakeMeeting() {
  const [selectedFeature, setSelectedFeature] = useState<
    'popMessage' | 'reaction' | 'roleManagement'
  >('popMessage');

  return (
    <Box as="section" py={{ md: '12' }}>
      <Box
        bg={mode('gray.50', 'gray.800')}
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '12', lg: '20' }}
        py={{ base: '12', md: '20' }}
      >
        <Box w="100%">
          <Heading size="xl" mb="4" fontWeight="extrabold">
            Interactive Meeting
          </Heading>
          <Text
            fontSize={{ md: 'lg' }}
            mb="6"
            color={mode('gray.600', 'gray.400')}
          >
            We provide features to help you host a delightful meeting
          </Text>
        </Box>
        <Flex
          alignItems="flex-start"
          flexDir={{ base: 'column-reverse', md: 'row' }}
          w="100%"
          mt={4}
        >
          <Stack
            flexDir="column"
            spacing="8"
            width={{ base: '100%', md: '30%' }}
          >
            <Feature
              title="Pop Message"
              color="#B2692A"
              isSelected={selectedFeature === 'popMessage'}
              onSelectFeature={() => setSelectedFeature('popMessage')}
            >
              Pop a block with a call-to-action button during the session.
              Increase the conversion rate for certain purpose.
            </Feature>
            <Feature
              title="Reaction"
              color="#2A6EB2"
              isSelected={selectedFeature === 'reaction'}
              onSelectFeature={() => setSelectedFeature('reaction')}
            >
              Without more to say, floating reactions could definitely bring a
              good vibe to the session.
            </Feature>
            <Feature
              title="Role Management"
              color="#2AB2A2"
              isSelected={selectedFeature === 'roleManagement'}
              onSelectFeature={() => setSelectedFeature('roleManagement')}
            >
              You could just switch the role of attendees with just a few
              clicks.
            </Feature>
          </Stack>
          <Img
            width={{ base: '100%', md: '60%' }}
            ml="auto"
            mb={8}
            objectFit="contain"
            src={`/images/meeting_${selectedFeature}.jpg`}
            alt="state of the art speaker"
          />
        </Flex>
      </Box>
    </Box>
  );
}

export default FakeMeeting;
