import { Box, BoxProps, Stack, useColorModeValue } from '@chakra-ui/react';

interface StatProps extends BoxProps {
  title: string;
  value: number;
}
export const Stat = (props: StatProps) => {
  const { title, value, ...rest } = props;
  return (
    <Stack
      direction="column-reverse"
      maxW="12rem"
      mx="auto"
      as="dl"
      textAlign="center"
      {...rest}
    >
      <Box
        as="dt"
        color={useColorModeValue('gray.600', 'whiteAlpha.700')}
        fontWeight="medium"
      >
        {title}
      </Box>
      <Box
        as="dd"
        fontSize="6xl"
        fontWeight="extrabold"
        color={useColorModeValue('brand.500', 'brand.300')}
      >
        {value}
      </Box>
    </Stack>
  );
};
