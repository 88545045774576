import {
  Box,
  Center,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import {
  IoIosCalendar,
  IoIosCard,
  IoMdBrowsers,
  IoMdHappy,
  IoMdPeople,
} from 'react-icons/io';

import { Feature } from './Feature';

function Features() {
  return (
    <Box as="section" bg={mode('gray.50', 'gray.800')} pt="16" pb="32">
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Heading
          textAlign="center"
          letterSpacing="tight"
          fontWeight="extrabold"
        >
          Really, Everything.
        </Heading>
        <Box mt="24">
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: '16', md: '8' }}
          >
            <Stack spacing="12" maxW="lg">
              <Feature
                icon={<Box as={IoMdBrowsers} w="6" h="6" />}
                title="Elegant Brand Page"
              >
                Build a tailored link-in-bio brand page to show upcoming
                sessions with our no code editor.
              </Feature>
              <Feature
                icon={<Box as={IoIosCalendar} w="6" h="6" />}
                title="Live session ticketing and management"
              >
                No more annoyed steps to purchase event ticket, collect email
                lists and send third-party meeting link.
              </Feature>
              <Feature
                icon={<Box as={IoMdHappy} w="6" h="6" />}
                title="In-web interactive live session"
              >
                A delightful meeting experience is important in this online
                meeting fatigue era. We provides Raising Hand, Pop Message,
                Reactions and Live Chat to help you make the session enjoyable.
              </Feature>
              <Feature
                icon={<Box as={IoIosCard} w="6" h="6" />}
                title="Monetize your community"
              >
                Nutshell provides a out-of-the-box payment gateway host paid
                event and monthly subscription.
              </Feature>
            </Stack>
            <Center
              height="fit-content"
              overflow="hidden"
              minH="18rem"
              shadow="md"
              borderRadius="lg"
            >
              <Img
                objectFit="contain"
                w="full"
                src="/images/brand.svg"
                alt="Event Page"
              />
            </Center>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}

export default Features;
