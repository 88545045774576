import { Box, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { FaHeart } from 'react-icons/fa';

const Heart = () => (
  <Box
    display="inline-block"
    mx="1"
    color={mode('red.500', 'red.300')}
    fontSize="xs"
    role="img"
    aria-label="Love"
    as={FaHeart}
  />
);

const Copyright = () => {
  return (
    <Text mt="2">
      &copy; {new Date().getFullYear()} Made with <Heart /> by{' '}
      <a href="https://kaik.com/?ref=live" target="_blank" rel="noreferrer">
        Kaik, Inc.
      </a>
    </Text>
  );
};

export default Copyright;
