import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';

import useOpenGraphLocale from '@lib/hooks/useOpenGraphLocale';

import LandingPage from '../containers/LandingPage';
import Layout from '../layouts/DefaultLayout';

function Home() {
  const { t } = useTranslation(['homepage']);
  const openGraphLocale = useOpenGraphLocale();

  return (
    <Layout>
      <NextSeo
        title={t('Nutshell Live')}
        description={t(
          'Build live session community for creators, gather audience together and build the connection'
        )}
        canonical="https://nutshell.live/"
        openGraph={{
          title: `Nutshell Live - ${t('Nutshell Live')}`,
          locale: openGraphLocale,
          description: t(
            'Build live session community for creators, gather audience together and build the connection'
          ),
          url: 'https://nutshell.live/',
          site_name: 'Nutshell Live',
        }}
        languageAlternates={[
          {
            hrefLang: 'en-US',
            href: 'https://nutshell.live/',
          },
          {
            hrefLang: 'zh-TW',
            href: 'https://nutshell.live/zh-TW',
          },
        ]}
      />
      <LandingPage />
    </Layout>
  );
}

export default Home;
