import { Box, HStack, Text } from '@chakra-ui/react';

interface FeatureProps {
  isSelected: boolean;
  onSelectFeature: () => void;
  title: string;
  children: React.ReactNode;
  color: string;
}
export const Feature = (props: FeatureProps) => {
  const { title, children, color, isSelected, onSelectFeature } = props;
  return (
    <Box
      cursor="pointer"
      p={4}
      borderRadius="lg"
      bg={isSelected ? 'gray.200' : 'transparent'}
      onClick={onSelectFeature}
    >
      <HStack spacing={3}>
        <Box height="24px" w="12px" backgroundColor={color} />
        <Text fontWeight="extrabold" fontSize="lg">
          {title}
        </Text>
      </HStack>
      <Text mt="3">{children}</Text>
    </Box>
  );
};
