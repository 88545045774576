import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import { UseCaseType } from './_data';

export const UseCase = (props: UseCaseType) => {
  const { name, description } = props;
  return (
    <Flex
      direction="column"
      bg={useColorModeValue('white', 'gray.700')}
      shadow={useColorModeValue('base', 'none')}
    >
      <Box p="6" flex="1">
        <Box mt="2">
          <Text fontWeight="bold">{name}</Text>
        </Box>
        <Text
          color={useColorModeValue('gray.600', 'whiteAlpha.800')}
          maxW={{ base: 'unset', md: '20rem' }}
          mt="6"
        >
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
