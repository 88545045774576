import { Box } from '@chakra-ui/react';

import FOMO from './components/FOMO';
import FakeMeeting from './components/FakeMeeting';
import Features from './components/Features';
import Hero from './components/Hero';
import Stats from './components/Stats';
import UseCases from './components/UseCases';

function LandingPage() {
  return (
    <Box width="100%">
      <Hero />
      <Features />
      <FakeMeeting />
      <Stats />
      <UseCases />
      <FOMO />
    </Box>
  );
}

export default LandingPage;
