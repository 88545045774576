import {
  Box,
  Heading,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { asyncPost } from '@lib/fetch';

import { Stat } from './Stat';

type LandingStatsType = {
  meetingsCount: number;
  brandsCount: number;
  userCount: number;
};

function Stats() {
  const [stats, setStats] = useState<LandingStatsType>();

  const handleFetchStats = useCallback(async () => {
    try {
      const data = (await asyncPost({
        url: '/api/op/getLandingPageStats',
        body: {},
      })) as LandingStatsType;
      setStats(data);
    } catch (err) {
      // Catch section
    }
  }, []);

  useEffect(() => {
    handleFetchStats();
  }, [handleFetchStats]);

  return (
    <Box
      as="section"
      maxW="7xl"
      mx="auto"
      px={{ base: '6', md: '8' }}
      py={{ base: '12', md: '20' }}
    >
      <Box mb="12" textAlign="center">
        <Heading size="xl" fontWeight="extrabold" lineHeight="normal">
          Start live sessions within your community
          <br />
          Just like others
        </Heading>
      </Box>
      <Stack
        spacing="8"
        direction={{ base: 'column', md: 'row' }}
        divider={<StackDivider />}
      >
        <Stat title="Brands" value={stats?.brandsCount || 0} />
        <Stat title="Live Sessions" value={stats?.meetingsCount || 0} />
        <Stat title="Reached Audience" value={stats?.userCount || 0} />
      </Stack>
    </Box>
  );
}

export default Stats;
