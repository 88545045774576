import { Box, Divider, Stack } from '@chakra-ui/react';

import Copyright from './Copyright';
import SubscribeForm from './SubscribeForm';
import { SocialMediaLinks } from './SocialMediaLinks';

type Props = {
  showForm?: boolean;
};

const Footer = ({ showForm = true }: Props) => (
  <Box
    as="footer"
    role="contentinfo"
    mx="auto"
    maxW="7xl"
    py="12"
    px={{ base: '4', md: '8' }}
  >
    <Stack spacing="10">
      {showForm && (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '10', lg: '28' }}
        >
          <Box flex="1"></Box>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '10', md: '20' }}
          >
            <SubscribeForm width={{ base: 'full', md: 'sm' }} />
          </Stack>
        </Stack>
      )}
      <Divider />
      <Stack
        direction={{ base: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Copyright />
        </Box>
        <SocialMediaLinks />
      </Stack>
    </Stack>
  </Box>
);

export default Footer;
