import { Box, Button, Heading, Stack, Text, chakra } from '@chakra-ui/react';

function FOMO() {
  return (
    <Box as="section">
      <Box
        maxW="3xl"
        mx="auto"
        px={{ base: '6', lg: '8' }}
        py={{ base: '16', sm: '20' }}
        textAlign="center"
      >
        {/* <Text
          fontWeight="semibold"
          color={useColorModeValue('brand.600', 'brand.200')}
        >
          60-Day money-back guarantee
        </Text> */}
        <Heading
          my="4"
          as="h2"
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="extrabold"
          letterSpacing="tight"
          lineHeight="1.2"
        >
          In a <chakra.span color="brand.500">Nutshell</chakra.span>
          <br />
          Nothing should stop you from holding a delightful live session.
        </Heading>
        <Text fontSize="lg" maxW="xl" mx="auto">
          No more annoyed steps switched between different apps, we take care of
          everything
        </Text>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          mt="10"
          justify="center"
          spacing={{ base: '3', md: '5' }}
          maxW="md"
          mx="auto"
        >
          <Button
            as="a"
            href="/signup"
            size="lg"
            h="16"
            px="10"
            colorScheme="brand"
            fontWeight="bold"
            flex={{ md: '1' }}
          >
            Get started free
          </Button>
          <Button
            flex={{ md: '1' }}
            variant="outline"
            size="lg"
            h="16"
            px="10"
            fontWeight="bold"
            className="cta-early-access"
          >
            Talk to an expert
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default FOMO;
