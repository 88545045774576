import {
  Box,
  Grid,
  HStack,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';

import { UseCase } from './UseCase';
import { useCases } from './_data';

function UseCases() {
  return (
    <Box as="section" bg={useColorModeValue('gray.50', 'gray.800')}>
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        py={{ base: '12', md: '20' }}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '24rem 1fr' }}
          gap={{ base: '8', md: '12', lg: '16' }}
        >
          <Box>
            <Heading
              size="2xl"
              letterSpacing="tight"
              mb="5"
              fontWeight="extrabold"
              color={useColorModeValue('brand.500', 'brand.300')}
            >
              Live sessions for every use-case
            </Heading>
            <Text
              fontSize="xl"
              maxW="2xl"
              color={useColorModeValue('blackAlpha.700', 'whiteAlpha.800')}
            >
              Raise your community to the next level.
            </Text>
          </Box>
          <SimpleGrid
            mt={{ base: '8', md: '0' }}
            columns={{ base: 1, md: 2 }}
            spacing="10"
          >
            {useCases.map((useCase, index) => (
              <UseCase
                key={index}
                name={useCase.name}
                description={useCase.description}
              />
            ))}
          </SimpleGrid>
        </Grid>
      </Box>
    </Box>
  );
}

export default UseCases;
