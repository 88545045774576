import {
  Box,
  Button,
  Circle,
  Heading,
  Img,
  LightMode,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue as mode,
} from '@chakra-ui/react';

function Hero() {
  return (
    <Box>
      <Box as="section" bg="#fcfcfc" color="gray.800" py="7.5rem">
        <Box
          maxW={{ base: 'xl', md: '5xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          <Box mx="auto" mb={4} width="fit-content">
            <a
              href="https://www.producthunt.com/posts/nutshell-live?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-nutshell-live"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=314401&theme=light"
                alt="Nutshell Live - All-in-one tool for hosting live sessions | Product Hunt"
                style={{ width: '200px', height: '40px' }}
              />
            </a>
          </Box>
          <Box textAlign="center">
            <Heading
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              maxW="48rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              We take care of everything to host a live session.
            </Heading>
            <Text fontSize="xl" mt="4" maxW="xl" mx="auto">
              We help you bring audience together through live sessions, from
              event page, link-in-bio, ticketing to in-web live session.
            </Text>
          </Box>

          <Stack
            justify="center"
            direction={{ base: 'column', md: 'row' }}
            mt="10"
            mb="20"
            spacing="4"
          >
            <LightMode>
              <Button
                as="a"
                href="/signup"
                size="lg"
                colorScheme="brand"
                px="8"
                fontWeight="bold"
                fontSize="md"
              >
                Get started free
              </Button>
            </LightMode>
          </Stack>

          <Box
            className="group"
            cursor="pointer"
            position="relative"
            rounded="lg"
            overflow="hidden"
          >
            <Img
              alt="Screenshot of Nutshell Live Meeting"
              src="/images/meeting.jpg"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
