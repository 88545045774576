import Head from 'next/head';
import { FC, useEffect } from 'react';

import Navbar from '../components/Navbar';
// import Header from "../components/Header";
import Footer from '../components/Footer';

// import {Box} from '@chakra-ui/react';
import { LayoutProps } from './Layout';

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#5bbad5" />
      </Head>
      <Navbar />
      {/* <Header /> */}
      {children}
      <Footer showForm={false} />
    </>
  );
};

export default Layout;
