import { BsCheckCircle } from 'react-icons/bs';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HTMLChakraProps,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FooterHeading } from './FooterHeading';

const hasWindow = () => {
  return typeof window === 'object';
};

type FormInputs = {
  email: string;
};

const SubscribeForm = (props: HTMLChakraProps<'form'>) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isValid },
  } = useForm<FormInputs>();

  const { t } = useTranslation(['footer']);

  const inputBgColor = useColorModeValue('white', 'inherit');
  const inputFocusBorderColor = useColorModeValue('blue.500', 'blue.300');
  const inputPlaceholderColor = useColorModeValue('gray.500', 'whiteAlpha.700');

  const onSubmit = async (data: FormInputs) => {
    if (!hasWindow()) {
      return;
    }

    const clientLanguage =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    const CK_FORM_ID = clientLanguage === 'zh-TW' ? '184244' : '2280520';
    const FORM_URL = `https://app.convertkit.com/forms/${CK_FORM_ID}/subscriptions`;

    try {
      await fetch(FORM_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: 'vOKS6SIqt5Vjn1Vt-icsEQ',
          email_address: data.email,
        }),
      });
    } catch (error) {}
  };

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)}>
      {!isSubmitSuccessful ? (
        <Stack spacing="4">
          <FooterHeading>{t('Subscribe to our newsletter')}</FooterHeading>
          <Text>{t('Get notified when we have exciting news for you.')}</Text>
          <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
            <FormControl isInvalid={!!errors.email}>
              <Input
                bg={inputBgColor}
                placeholder={t('Enter your email')}
                type="text"
                focusBorderColor={inputFocusBorderColor}
                _placeholder={{
                  opacity: 1,
                  color: inputPlaceholderColor,
                }}
                {...register('email', {
                  pattern: /\S+@\S+\.\S+/,
                  required: true,
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.type === 'required' && (
                  <>Email is required.</>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <>Email is not valid.</>
                )}
              </FormErrorMessage>
              {!isValid && !errors.email ? (
                <FormHelperText>
                  {t("We'll never share your email.")}
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
            <Button
              isLoading={isSubmitting}
              type="submit"
              colorScheme="blue"
              flexShrink={0}
              width={{ base: 'full', md: 'auto' }}
            >
              {t('Subscribe')}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing="4" align="center">
          <Icon w={12} h={12} color="blue.500" as={BsCheckCircle} />
          <Heading as="h3" size="md">
            {t('Thank you for your subscription.')}
          </Heading>
          <Text>
            {t('We will contact you as soon as we have news to share.')}
          </Text>
        </Stack>
      )}
    </chakra.form>
  );
};

export default SubscribeForm;
