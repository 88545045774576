import { useRouter } from 'next/router';

const useOpenGraphLocale = () => {
  const { locale } = useRouter();

  if (!locale) {
    return 'en_US';
  }

  // The locale these tags are marked up in. Of the format language_TERRITORY. Default is en_US.
  // ref: https://ogp.me/ `og:locale`
  return locale.replace(/-/, '_');
};

export default useOpenGraphLocale;
