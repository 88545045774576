export interface UseCaseType {
  name: string;
  description: string;
}

export const useCases: UseCaseType[] = [
  {
    name: 'Workout Sessions',
    description:
      'A tailored workout event page with selling paid ticket, we help coaches offer live workout session with ease.',
  },
  {
    name: 'Language Learning Groups',
    description:
      'With the role management in the live session, teacher could arrange conversation with each students easily.',
  },
  {
    name: 'Study Groups',
    description:
      'No more messaging app groups to share the meeting link, gather your study buddy, send invitation, notification and join the live session all in Nutshell.',
  },
  {
    name: 'Startups & Brands',
    description:
      'An engaged community is valuable for any brand. Host and manage live sessions for different types of clients with just a few clicks.',
  },
  {
    name: 'Podcasters',
    description:
      'Bring your listeners together to discuss about the latest episode, host a recurring Q&A session with listeners.',
  },
  {
    name: 'Membership Communities',
    description:
      'Nutshell help you to bring them all together in a live session and monetize your community.',
  },
];
